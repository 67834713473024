/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material';
import MainLayout from 'layouts/MainLayout';
import { AnimatedContainer } from 'components/containers';
import { useOfferItems } from 'hooks';
import ContactImg from 'images/Mail-amico.svg';
import bgrLightVert from 'images/bgr/bgr-light-vert.inline.png';

const Contact = () => {
  const items = useOfferItems();
  const intl = useIntl();
  const [loading, setLoading] = useState(true);

  const initializeHubspotForm = () => {
    if ('hbspt' in window) {
      // @ts-ignore
      window.hbspt.forms.create({
        region: 'eu1',
        portalId: '26229267',
        formId: '6bbcb137-4d24-4207-971e-14161281ce02',
        target: '#formContainer'
      });
      setLoading(false);
    } else {
      setTimeout(initializeHubspotForm, 200);
    }
  };

  useEffect(() => {
    initializeHubspotForm();
  }, []);

  return (
    <MainLayout pageTitle={`${intl.formatMessage({ id: 'Contact' })} | NextGen`} offerItems={items} darkNavbar>
      <AnimatedContainer delay={100}>
        <Box sx={{ background: `url(${bgrLightVert}) no-repeat top center/auto` }}>
          <Container sx={{ margin: '0 auto', pt: { xs: 6, md: 8 }, pb: 5 }}>
            <Typography
              color="common.dark"
              variant="h1"
              sx={{ whiteSpace: 'pre-line' }}
              mb={2}
              textAlign={{ xs: 'center', md: 'left' }}
            >
              {intl.formatMessage({ id: 'Contact Us' })}
            </Typography>
            <Grid
              container
              alignItems={{ xs: 'center', md: 'stretch' }}
              justifyContent="space-between"
              flexDirection={{ xs: 'column', md: 'row' }}
              rowSpacing={{ xs: 0, md: 2 }}
              columnSpacing={{ xs: 0, md: 2 }}
              columns={{ xs: 10, md: 12 }}
            >
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h4"
                  mt={3}
                  sx={{ whiteSpace: 'pre-line' }}
                  textAlign={{ xs: 'center', md: 'left' }}
                >
                  {intl.formatMessage({ id: 'contact-page-subtitle' })}
                </Typography>
                <Box sx={contactImgStyle}>
                  <ContactImg />
                </Box>
              </Grid>
              <Grid item mt={{ xs: 2, md: 0 }} xs={12} sm={7} md={6}>
                {loading && (
                  <Box sx={loaderBoxStyle}>
                    <CircularProgress color="secondary" />
                  </Box>
                )}
                <Container sx={{ '& #formContainer': { width: '100%' } }}>
                  <div id="formContainer"></div>
                </Container>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </AnimatedContainer>
    </MainLayout>
  );
};

export default Contact;

const contactImgStyle = {
  mt: 5,
  '& svg': {
    width: '100%',
    minWidth: '100%',
    minHeight: '100%',
    pr: { md: 16 },
    display: { xs: 'none', md: 'block' }
  }
};
const loaderBoxStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%'
};
